
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OHomeInfoBlock',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
})
